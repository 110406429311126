.individual-userprofile-img>img {
    width: 130px;
    padding: 3px;
    border: 3px solid #d2d6de;
    height: 120px;
    margin-right: 20px;
}

.individual-user-info-comp>form {
    display: flex;
    justify-content: center;
    background-color: transparent;
    align-items: center;
}

.individual-user-info-view {
    display: flex;
    justify-content: center;
}



.individual-userinfo-table {
    width: 100%;
}


@media(max-width: 768px) {

    .custom-tooltip-helptext {
        width: 98% !important;
        left: 3px !important;
        word-break: break-word;
    }

    .individual-user-info-comp>form {
        flex-direction: column;
    }
}

.individual-userinfo-cell-leftside {
    text-align: right;
    letter-spacing: 1px;
    padding-right: 30px;
}

.individual-userinfo-cell-rightside {
    text-align: left;
    font-size: 16px !important;
    letter-spacing: 1px;
    word-break: break-word;
    padding-left: 30px;
    line-height: normal;

}

.individual-userinfo {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.individual-userinfo-table>tbody>tr>td {
    border: 0.5px dashed #dce0dd94;
    padding: 5px;
    width: 50%;
}



/*  for user info copied from internet */
.user-heading {
    width: 100%;
    word-break: break-all;
    padding-bottom: 10px;
    text-align: center;
    margin: 10px 0 0;
}

.profile-email-shown {
    font-size: 20px !important;
    letter-spacing: 1px;
}

.profile-view-row {
    justify-content: center;
}



.profile-info-unshown {
    color: #808080;
}

.suggested-email-view {
    color: #1a0dab !important;
    cursor: pointer !important;
}

.api-limit-error {
    text-align: center;
}

.bad-request-tryagain {
    color: #007bff;
    cursor: pointer;
}

.bad-request-tryagain:hover {
    text-decoration: underline;
}

.dailylimit-help-tip {
    text-align: center;
    background-color: #4092c3;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 12px;
    line-height: 15px;
    display: inline-block;
    margin-left: 3px;
}

.help-tip {
    text-align: center;
    background-color: #c5cbcf;
    border-radius: 50%;
    width: 16px;
    height: 15px;
    font-size: 13px;
    line-height: 20px;
    display: inline-block;
    margin-left: 3px;
}

.help-tip-create-app {
    text-align: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    margin-left: 3px;
    background-color: rgb(244 224 202)
}

.help-tip-create-app::before {
    color: #f86503;
    content: '?';
    font-weight: bold;
    line-height: normal;
    vertical-align: top;
}

.help-tip:hover {
    cursor: pointer;
}

.help-tip:before {
    content: '?';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
}

.result-name-data:hover .status-help-tip {
    display: inline-block;
}

.result-table-names:hover .status-help-tip {
    display: inline-block;
}

.status-help-tip {
    display: none;
}

.status-help-tip[data-for='admin-remaining-tooltip'],
.status-help-tip[data-for='upgrade-pricing-tooltip'] {
    display: block !important;
}

.status-help-tip {
    text-align: center;
    background-color: #ccc;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 12px;
    line-height: 20px;
    margin-left: 3px;
}

.status-help-tip:before {
    content: 'i';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
}

.all-status-help-tip {
    text-align: center;
    background-color: #ccc;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 11px;
    line-height: 18px;
    display: inline-block;
    margin-left: 4px;
    padding-right: 1px;
}

.all-status-help-tip:hover {
    cursor: pointer;
}

.all-status-help-tip:before {
    content: 'i';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
    font-style: italic;
}

.dailylimit-help-tip .custom-tooltip-helptext {
    font-weight: 500;
    /* word-break: break-word; */
}

.dailylimit-help-tip:hover {
    cursor: pointer;
}

.dailylimit-help-tip:before {
    content: '?';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
}

.catch-all-message {
    color: blue;
    margin-bottom: 0;
    font-size: 14px;
}



.widget-user {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    margin-bottom: 20px;
    width: 100%;
    /* box-shadow: 0 1px 1px rgba(0,0,0,0.1); */
    margin-top: 50px;
}

.widget-user .widget-user-image {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -45px;
}

.widget-user .widget-user-image>img {
    width: 90px;
    height: auto;
    /* border: 3px solid #fff; */
    border-radius: 50%;
}

.widget-user-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #f4f4f4;
    padding: 30px;
    background-color: #ffffff;
    padding-top: 50px;
}

.custom-tooltip-helptext.add-user-model-text {
    text-align: left;
    /* background-color: #505050 !important; */
    /* color: #334a4a !important; */
    font-weight: 800;
    width: 170px;
    opacity: 1;
}

.custom-tooltip-helptext {
    text-align: left;
    /* background-color: #505050 !important; */
    /* color: #334a4a !important; */
    font-weight: 800;
    width: 350px;
    /* opacity: 0.7; */
    word-break: break-word;
}

#admin-remaining-tooltip.custom-tooltip-helptext {
    width: auto;
}

/* .custom-tooltip-helptext:after {
    border-bottom-color: #505050 !important;
} */

#deliverability-help-text {
    max-width: 270px;
}

.top-heading {
    justify-content: center;
}

.top-heading h3 {
    text-align: center;
    font-size: 30px !important;
    /* color: #f8b467 !important; */
    color: #334a4a !important;
    font-weight: 400;
    line-height: 1.2;
}



.custum-sign-up-button a:hover {
    color: #fff;
    background: #334a4a !important;
    border-radius: 3px !important;
    border-color: #334a4a !important;
    letter-spacing: 1px;
    font-weight: 600;
}

.instant-search-form {
    max-width: 450px;
    margin: 0px auto;
}


.__react_component_tooltip.type-dark {
    opacity: 0.7;
    font-weight: bold;
}

.__react_component_tooltip.type-dark#upgradePricing {
    width: 300px;
}