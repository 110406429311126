.quick-userverify-button {
    margin: 0px 0px 0px 0px;
    /* background: #fab567;
    border-color: #fab567; */
    color: #fff;
    border-radius: 0px;
    padding: 6px 25px;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
}

.quick-userverify-button:focus {
    box-shadow: 1px 0px 10px 2px #f1c695d7;
}

.quick-search-form {
    max-width: 900px;
    margin: 0px auto;
}

.quick-email-input-form {
    margin-bottom: 2px;
    letter-spacing: 1px;
    border-color: rgba(var(--theme-button-color), 0.7) !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: solid 1px #ccc;
    border-radius: 4px;
    color: #555;
    padding: 7px;
    position: relative;
}

.quick-email-input-form:focus {
    border-color: rgba(var(--theme-button-color), 0.7) !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: solid 1px #ccc;
    border-radius: 4px;
    color: #555;
    padding: 7px;
    position: relative;
}

.lists-table-header {
    background-color: #0000000d;
    padding: 10px !important;
    vertical-align: text-top !important;
    font-weight: 501;
    border-bottom: 0px !important;
}


.lists-table-cell {
    padding-top: 10px !important;
}

.lists-table-cell .delivery_status {
    letter-spacing: 1px;
    word-break: break-word;
    line-height: normal;
    font-weight: normal;
}

.lists-table {
    border-radius: 0.5rem;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.lists-table table {
    min-width: 700px;
    table-layout: auto;
}

.table-hover tbody tr.lists-table-row:hover {
    background-color: #f6f8fa;
    cursor: pointer;
}

.lists-table>table:first-child {
    margin-bottom: 0;
}

.suggested-text {
    text-align: start !important;
}

.quick-validation-table tbody {
    max-height: 100% !important;
    overflow-y: auto;
}

.status-column {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.show-json-button {
    font-size: 14px;
    margin: 5px 0px;
}

.account-type_subheading {
    font-weight: 600;
    align-self: flex-end;
}

.copy-and-show-json_icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    vertical-align: top;
    cursor: pointer;
}

.sparkle-icon{
    color: rgba(var(--theme-button-color));
    font-size: 20px;
}

@media(min-width: 769px) {
    .lists-table tbody {
        display: block;
        max-height: calc(37vh);
        overflow-y: scroll;
    }

    .lists-table thead {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .lists-table tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}

@media(max-width: 768px) {
    .quick-userverify-button {
        width: 100%;
        margin-top: 10px;
    }

    .quick-validation-modal .modal-body {
        overflow-y: auto;
    }

    .lists-table {
        max-height: 100%;
    }

    .quick-validation-modal .modal-content {
        height: 100%;
    }

    .lists-table-header {
        width: 150px;
    }
}

.max-emails-note {
    font-size: 14px;
    max-width: 750px;
    margin: 0px auto;
    margin-top: 5px;
    text-align: left;
}

@media screen and (max-width: 950px) {
    .max-emails-note {
        text-align: center;
    }
}