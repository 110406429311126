.users-roles-select {
  width: 200px;
  padding-right: 10px
}

.seats-select {
  width: 100px !important;
}

.users-accounts {
  border: 1px solid #dee2e6;
  border-top: none;
}

.users-accounts,
.teams-table,
.team-members-table {
  background-color: #fff;
}

.teams-table {
  border: 1px solid #dee2e6;
  border-top: none;
}

.teams-table th {
  font-weight: 501;
  border-bottom: 1px solid #dee2e6 !important;
  background-color: #f2f2f2;
}

.users-accounts>table,
.teams-table>table,
.team-members-table>table {
  margin-bottom: 0px;
}

.register-phone-number .PhoneInputCountry .PhoneInputCountryIcon>img {
  vertical-align: super;
}

.register-phone-number input {
  height: 1.8rem;
  outline: none;
  border-radius: 0px;
  padding: 0px;
  appearance: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-image: initial;
  border-bottom: 1px solid rgb(197, 210, 224);
  transition: border 0.1s ease 0s;
  font-size: inherit;
}

.register-phone-number .PhoneInputCountry .PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 0.3em;
  border-bottom-width: 0;
  border-top-width: 0.35em;
  border-bottom-style: solid;
  border-top-style: solid;
  border-left: 0.2em solid transparent;
  border-right: 0.2em solid transparent;
  color: #b8bdc4 !important;
  opacity: .7;
  transition: color .1s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.adduser-form-headings {
  font-size: 14px;
  margin: 0px;
}

.mandatory-feild-indicator {
  color: red;
}

.limits-column-alignment {
  text-align: right;
}

.credits-column-alignment {
  text-align: center;
}

/* .users-accounts>table>tbody>tr {
    cursor: pointer;
} */

.member-seat-count {
  display: flex;
}

.seat-help-tip[data-for='seat-tooltip'] {
  display: block !important;
}

#seat-tooltip.custom-tooltip-seat {
  width: auto;
}

.seat-help-tip {
  text-align: center;
  background-color: #ccc;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 12px;
  line-height: 20px;
  margin-left: 9px;
}

.seat-help-tip:before {
  content: 'i';
  font-weight: bold;
  color: #fff;
  line-height: normal;
  vertical-align: top;
}

.flex-grow {
  flex-grow: 1
}

.admin-toggle .react-toggle-track {
  width: 82px !important;
  height: 28px !important;
}


.admin-toggle.react-toggle--checked .react-toggle-thumb {
  left: 55px !important;
}

.admin-toggle.react-toggle--checked .react-toggle-track-check {
  font-size: 16px !important;
  margin-top: 13px !important;
  left: 11px !important;
}

.admin-toggle {
  font-size: 15px !important;
}

.admin-toggle .react-toggle-track-x {
  margin-top: 14px !important;
  right: 44px !important;
}

.admin-toggle .react-toggle-thumb {
  width: 26px !important;
  height: 26px !important;
  border: 1px solid #fff !important;
}

.admin-toggle .react-toggle-track {
  color: rgb(255, 255, 255);
  background-color: #808080 !important;
  border: 2px solid #808080 !important;
}

.admin-toggle.react-toggle--checked .react-toggle-track {
  background-color: rgba(var(--theme-color)) !important;
  border: 2px solid rgba(var(--theme-color)) !important;
}

/* .notification-title{
    font-size: 18px!important;
}
.notification-message{
    font-size: 16px!important;
} */
/*.react-toggle-track {
    background-color: #f1cf46 !important;
}*/

@media(min-width:768px) {

  /* user table fixed column */
  .users-accounts>table .users-page-fixed-column,
  .team-members-table>table .users-page-fixed-column {
    position: sticky;
    background-color: #fff;
    position: -webkit-sticky;
    left: 0;
    /* z-index: 2; */
  }

  .users-accounts>table>tbody tr.selected-row-bgcolor .users-page-fixed-column,
  .users-accounts>table>tbody>tr.selected-row-bgcolor>td:nth-child(1),
  .team-members-table>table>tbody>tr.selected-row-bgcolor>td:nth-child(1),
  .team-members-table>table>tbody tr.selected-row-bgcolor .users-page-fixed-column {
    position: sticky;
    background-color: #e0e0e0;
    position: -webkit-sticky;
    left: 0;
    border-top: 1px solid #e8e8e8;
    z-index: 2;
  }

  .users-accounts>table>thead>tr>th:nth-child(2),
  .team-members-table>table>thead>tr>th:nth-child(1) {
    position: sticky;
    background-color: #FFF;
    position: -webkit-sticky;
    left: 0;
    border-top: 1px solid #e8e8e8;
    z-index: 2;
  }


  /*.users-accounts>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column:hover,
    .users-accounts>table>tbody>tr:not(.select-row-bgcolor):hover td,
    .team-members-table>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column:hover,
    .team-members-table>table>tbody>tr:not(.select-row-bgcolor):hover td
    {
        position: sticky;
        background-color: #e0e0e0;
        position: -webkit-sticky;
        left: 0;
        border-top: 1px solid #e8e8e8;
    }*/

  .users-accounts>table .users-page-fixed-column,
  .users-accounts>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column,
  .team-members-table>table .users-page-fixed-column,
  .team-members-table>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column {
    position: sticky;
    background-color: #fff;
    position: -webkit-sticky;
    left: 0;
    z-index: 2;
  }

  .member-seat-count {
    margin-left: 300px;
    flex-direction: row-reverse;
    padding-bottom: 5px;
    margin-top: -25px;
    align-items: center;
  }
}


.admin-tick-icon {
  color: rgb(0, 255, 13) !important;
  border: none;
  background-color: unset;
  padding: 0 0 0 8px;
  line-height: normal;
}

.allow-unlimited-checkbox {
  font-size: 13px;
  z-index: unset !important;
}

.overview-page-bar {
  background-color: transparent;
  margin-right: 10px;
  margin-left: 10px;
  width: 110px;
  height: 37px;
  padding-top: 3px;
}

.overview-page-bar.nav-tabs .nav-item {
  margin-bottom: 0 !important;
}

.overview-page-bar.nav-tabs {
  border-bottom: 0;
}

.overview-page-bar.nav-tabs #individual.nav-link.active {
  background-color: transparent;
  color: rgba(var(--theme-color));
  border: 1px solid rgba(var(--theme-color));
  ;
  box-shadow: 0px 0px 2px 0px;
}

.overview-page-bar.nav-tabs #individual.nav-link {
  background-color: transparent;
  color: #b7b7b7;
  font-size: 14px;
  border: 1px solid #b7b7b745;
  border-right: 1px solid rgba(var(--theme-color));

}

.overview-page-bar.nav-tabs #organization.nav-link.active {
  background-color: transparent;
  color: rgba(var(--theme-color));
  border: 1px solid rgba(var(--theme-color));
  box-shadow: 0px 0px 2px 0px;
}

.overview-page-bar.nav-tabs #organization.nav-link {
  background-color: transparent;
  color: #b7b7b7;
  font-size: 14px;
  border: 1px solid #b7b7b745;
  border-left: 1px solid rgba(var(--theme-color));

}

.overview-page-bar.nav-tabs .nav-link {
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-color: #f9f9f9;
  padding: 5px 10px;
  font-size: 17px;
  color: #404040;
}

.overview-page-bar li a:hover {
  cursor: pointer;
}

.overview-pageheading {
  display: flex;
  padding-left: 0;
}

.overview-pageheading>p {
  margin-bottom: 0;

}

.members-table-search {
  width: 50%;
}

.user-filters {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.user-lists-filters {
  padding: 0;
  display: flex;
  width: 91%;
  margin-right: 10px;
  flex-direction: column;
}

.admin-delete-icon {
  color: red !important;
  border: none;
  background-color: unset;
  padding: 0;
  line-height: normal;
}

.admin-delete-icon:focus {
  outline: none;
}

.admin-edit-icon:focus {
  outline: none;
}

.admin-delete-icon-disabled,
.admin-edit-icon-disabled {
  color: #697386 !important;
  border: none;
  background-color: unset;
  cursor: not-allowed !important;
  padding: 0;
  line-height: normal;
}

.admin-edit-icon {
  color: #007bff !important;
  border: none;
  background-color: unset;
  padding: 0;
  line-height: normal;
}

.user-lists-btn {
  display: flex;
  margin-top: -80px;
  align-items: flex-start;
}

.team-filter-badge {
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  background-color: #03A9F4;
  margin-bottom: 2px;
}

.team-filter-badge .close-icon:before {
  color: #fff;
}

.admin-member-tab {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media(max-width:1000px) {
  .profile-page-content>div:first-child {
    flex-direction: column;
  }

  .profile-page-content>div:first-child>div:nth-child(2) {
    align-self: unset !important;
  }
}

@media(max-width: 768px) {

  .overview-page-bar {
    flex-direction: row !important;
    justify-content: space-evenly;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .overview-page-bar.nav-tabs .nav-link.active {
    border-right: 0px !important;
    border-bottom: 3px solid rgba(var(--theme-color));
  }

  .user-filters {
    flex-direction: column;
  }

  .user-lists-filters {
    width: 100%;
    margin-right: 0;
  }

  .user-lists-filters>div {
    flex-direction: column;
  }

  .user-lists-btn {
    justify-content: space-between;
    margin-top: 1px !important;
    width: 100%;
    margin-bottom: 10px;
  }

  input[type=search].search-empty {
    margin-bottom: 5px;
    padding-right: 1px;
  }

  .member-seat-count {
    flex-direction: row;
    padding-bottom: 10px !important;
    width: 100% !important;
  }
}

.api-rate-limit-time-select,
.api-rate-limit-source-select {
  width: 150px;
  /* padding-right: 10px */
}

.custom-plus-icon {
  padding-top: 1px !important;
}

.welcome-title-box {
  padding-bottom: 0px;
  padding-top: 25px;
  padding-left: 20px;
  /* font-size: 24px; */
}

.welcome-sub-title-box {
  padding-top: 12px;
  padding-left: 20px;
  font-size: 17px;
  font-weight: 500;
}

/* .modal-bg > .modal-content {
    background-color: #fbf5ec;
}

.modal-bg .modal-footer {
    background-color: #fbf5ec !important;
} */

.welcome_txt {
  font-size: 24px;
  /* width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  font-weight: 600;
}

.welcome-icon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.welcome_modal_custom_btn_width {
  font-weight: 501;
  width: 162px;
}

/* .alert-modal .modal-footer {
    justify-content: center !important;
}  */

.welcome-start-btn {
  margin-right: 30px;
  margin-bottom: 20px;
}

.custom-tooltip-popup {
  font-weight: 800;
  width: auto;
  word-break: break-word;
}

.welcome-container .card {
  border-radius: 0.85rem !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.welcome-modal .modal-body {
  padding: 0rem 1rem !important;
}

.welcome-modal .modal-header {
  padding: 0.5rem 1rem !important;
  border-bottom: 2px solid #eceeed42 !important;
}

.welcome-modal .modal-footer {
  /* background-color: #f5f5f5 !important; */
  border-top: 2px solid #eceeed42 !important;
  padding: 0 1rem !important;
}

.welcome-modal .modal-footer a {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.welcome-modal a {
  color: #ff9e30 !important;
  font-weight: 600;
}

.welcome-container .card {
  width: 100%;
  padding: 0;
  min-height: 105px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  box-shadow: 0.4px 0.4px 3px 0px #eceeed;
  -moz-box-shadow: 0.4px 0.4px 3px 0px #eceeed;
  -webkit-box-shadow: 0.4px 0.4px 3px 0px #eceeed;
  transition: 0.3s;
}

.welcome-container .card:hover {
  /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); */
  transform: translateY(2px);
  box-shadow: 1px 1px 14px 0px #eceeed;
  -moz-box-shadow: 1px 1px 14px 0px #eceeed;
  -webkit-box-shadow: 1px 1px 14px 0px #eceeed;
}

.welcome-container .card {
  border: 0 !important;
  cursor: pointer;
}

.welcome-container .card .card-body {
  padding: 0.5rem 1rem;
}

.welcome-container .card .card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0rem;
}

.welcome-title-box {
  margin-top: -17px !important;
}

.welcome_txt {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.welcome-modal-item-description {
  font-weight: 400 !important;
}

.welcome-container .card-title-1:before {
  content: '\f21d';
  font-family: FontAwesome;
  display: inline-block;
  /* margin-left: -1.3em; */
  width: 1.3em;
  /* padding-right: 5px !important; */
}

.welcome-container .start:before {
  content: '\f16a';
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  color: rgba(var(--theme-color));
}

.welcome-container .auto:before {
  content: '\f003';
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em;
  color: rgba(var(--theme-color));
}


.welcome-container .search:before {
  content: '\f002';
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em;
  color: rgba(var(--theme-color));
}

.welcome-container .base:before {
  content: '\f0c0';
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em;
  color: rgba(var(--theme-color));
}

.welcome-container .api:before {
  content: '\f135';
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em;
  color: rgba(var(--theme-color));
}

.welcome-container .card .content {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  align-items: flex-start;
  justify-content: flex-start;
}

.welcome-container .card .cta-button {
  display: flex !important;
  width: 100%;
  flex-basis: 30%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

/* @media(min-width:768px) {
  .welcome-container .icon {
    min-height: 50px !important;
  }
} */

@media(min-width:390px) {
  .modal.show .modal-dialog.welcome-modal {
    transform: translate(0, 5%);
  }
}

.welcome-modal,
.gray {
  color: #334a4a !important;
}

.orange {
  color: #ff9e30 !important;
}

.welcome-modal .modal-header .close {
  padding: 1rem 2rem;
  margin: 0rem -1rem -1rem auto;
}

.cust-icon {
  height: 20px;
  padding-right: 8px;
  margin-top: -4px;
}

@media(max-width:768px) {
  .welcome-container .card {
    flex-direction: column;
    height: fit-content;
    max-height: 220px;
  }

  .welcome-container .card .content {
    flex-basis: 80%;
  }

  .welcome-container .card .cta-button {
    flex-basis: 20%;
  }

  .welcome-container .card .cta-button .btn {
    padding: 0.5rem 1rem;
    width: 90px;
  }

  .welcome-container .container {
    padding-right: 0;
    padding-left: 0;
  }

  .welcome-title-box {
    margin-top: -22px !important;
  }

  .welcome-modal .modal-header .close {
    padding: 0.2rem 2rem;
  }

  .welcome-modal .modal-body {
    padding-top: 2px !important;
    padding-bottom: 4px !important;
  }

  .welcome-modal .card-body {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .welcome-modal .card-title {
    margin-bottom: 0.5rem;
  }

  .welcome-container .card {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .welcome-container .container .row {
    padding-bottom: 0 !important;
  }

  .welcome-modal .modal-header {
    padding: 0.45rem 0rem;
  }

  .welcome-modal .mb-4,
  .welcome-modal .my-4 {
    margin-bottom: 0.7rem !important;
  }

  .welcome-modal .mt-4,
  .welcome-modal .my-4 {
    margin-top: 0.7rem !important;
  }
}

.btn-padding {
  padding-left: 30px;
}

.status-help-tip[data-for='seat-request-popup-tooltip'] {
  display: inline-block !important;
}

.footer-lable {
  padding-left: 10px;
}

.right-side-list-container {
  height: 430px !important;
  overflow-y: auto;
}

.members-filters-div input {
  border: 1px solid #c3c3c3 !important;
  outline: none !important;
  box-shadow: none !important;
}

.team-info-breadcrumb {
  padding: 10px 0px 0px 5px;
}

@media (max-width:460px) {
  .team-accounts-subheader-btn-container {
    flex-direction: column-reverse;
    gap: 1rem;
    padding-top: 0.5rem;
  }
}