.custom-pagination {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding-left: 0;
  margin: 0px 0 0px 0px;
  /* border-radius: 4px; */
}

.custom-pagination>li {
  display: inline;
}

.custom-pagination>li>a,
.custom-pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 13px;
  margin-left: 8px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
}

.custom-pagination>li>a:hover,
.custom-pagination>li>span:hover,
.custom-pagination>li>a:focus,
.custom-pagination>li>span:focus {
  z-index: 3;
  border: 1px solid #f3f3f3;
  cursor: pointer;
}

.custom-pagination>.active>a,
.custom-pagination>.active>span,
.custom-pagination>.active>a:hover,
.custom-pagination>.active>span:hover,
.custom-pagination>.active>a:focus,
.custom-pagination>.active>span:focus {
  z-index: 2;
  color: #337ab7;
  cursor: default;
  background-color: transparent;
  border-bottom: 2px solid #337ab7;
  outline: none;
}

.custom-pagination>.disabled>span,
.custom-pagination>.disabled>span:hover,
.custom-pagination>.disabled>span:focus,
.custom-pagination>.disabled>a,
.custom-pagination>.disabled>a:hover,
.custom-pagination>.disabled>a:focus {
  color: #337ab7;
  cursor: not-allowed;
  background-color: #ddd;
  opacity: 0.5;
}

.custom-pagination-lg>li>a,
.custom-pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.custom-pagination-lg>li:first-child>a,
.custom-pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.custom-pagination-lg>li:last-child>a,
.custom-pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-pagination-sm>li>a,
.custom-pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.custom-pagination-sm>li:first-child>a,
.custom-pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.custom-pagination-sm>li:last-child>a,
.custom-pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pagination-filter-select {
  width: 90px;
}


/* ----------for react bootstrap table -------------*/
.react-bootstrap-table-page-btns-ul.pagination{
  justify-content: flex-end;
}

.react-bootstrap-table-page-btns-ul .page-item.active .page-link{
    color: #337ab7;
    cursor: default;
    background-color: transparent;
    border-bottom: 2px solid #337ab7;
    outline: none;
    border-radius: 0;
    border-left : 0;
    border-top: 0;
    border-right: 0;
    padding: 6px 13px;
}

.react-bootstrap-table-page-btns-ul .page-item .page-link{
  border: none;
  background-color: transparent;
  color: #337ab7;
  background-color: #fff;
  margin-left: 8px;
  border-radius: 0;
}

.react-bootstrap-table-page-btns-ul .disabled.page-item .page-link{
  background-color: #ddd;
  cursor: not-allowed;
  pointer-events: auto;
  opacity: 0.5;
}

.react-bootstrap-table-page-btns-ul .disabled.page-item .page-link:hover{
  cursor: not-allowed;
}

.react-bootstrap-table-page-btns-ul .page-item .page-link:focus{
  box-shadow: none;
}
