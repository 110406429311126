@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic);
@import url('https://fonts.googleapis.com/css?family=Lekton');

/* @import url('./theme.css'); */
@import url('./footer.css');
@import url('./custom-pagination.css');
@import url('./mylists.css');
@import url('./admin.css');
@import url('./announcement-bar.css');

body {
  color: #404040;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: hidden;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#main-container {
  position: relative;
  padding: 0 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100vh - 99px); */
}

::-webkit-scrollbar {
  width: 5px;
  /*Width of the scrollbar */
}

::-webkit-scrollbar {
  height: 5px;
  /* Height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: rgb(151, 150, 150);
  border-radius: 10px;
  /* Color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(151, 150, 150);
  /* Color of the scrollbar thumb on hover */
}

.page-header {
  padding-top: 1rem;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 0;
  display: block;
  margin: auto;
}

.app-headings {
  padding-top: 1rem;
  padding-bottom: 10px;
}

.page-sub-header {
  padding-top: 5px;
  padding-bottom: 0px;
}

.page-panel-heading {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-panel-heading>h3 {
  font-size: 18px;
  margin: 0;
  color: #7b7b7b;
}

.page-main-heading {
  margin-bottom: 0;
  font-size: 20px !important;
  color: black !important;
}

.page-main-heading-subtext {
  font-size: 15px;
  color: #3a3a3a;
  font-family: var(--theme-font-family);
}

.page-content-title {
  font-size: 22px;
  color: black;
  padding-top: 5px;
}

.page-sub-heading {
  font-size: 15px;
  color: #4c4c4c;
  margin-bottom: 0px;
  line-height: normal;
}

.page-sub-header-nav .nav-item:last-child>a.nav-link {
  margin-right: 0;
}

.page-content {
  padding: 0;
}

/* overview page css */
.overview-page-content {
  background-color: #fff;
  margin-bottom: 15px;
  border: 1px solid #f5f5f5;
}

.page-panel-content {
  padding: 15px;
}

.line-chart-preset {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.line-chart-preset li>a {
  padding: 3px 5px 2px 5px;
}

.line-chart-preset li:hover {
  cursor: pointer;
}

.profile-page-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0.1rem;
  background-color: #f6f6f6;
  border-radius: 6px;
}

.profile-page-content .infobox {
  background-color: #fff;
  padding: 0 5px 5px 5px;
  border: 1px solid #f5f5f5;
}

.profile-form-data-display,
.card-details-display,
.credit-details-display {
  margin-bottom: 0;
}

.profile-form-data-display td,
.credit-details-display td {
  padding: 5px 5px 0 5px;
}

.card-details-display td {
  padding: 5px 5px 5px 10px;
}

.card-details-display {
  background-color: white;
}

.profile-form-data-display .profile-name p,
.profile-form-data-display .profile-emailaddress p,
.card-details-display .card-data p,
.credit-details-display .credit-data p {
  margin-bottom: 0;
}

.profile-form-data-display .profile-emailaddress {
  text-align: right;
}

.edit-email-modal .modal-header {
  padding: 10px 10px;
}

.edit-email-modal .modal-header .modal-title {
  color: #4c4c4c;
}

.cards-list {
  max-height: 110px;
  display: block;
  overflow: hidden;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
}

.cards-list:hover {
  overflow-y: scroll;
}

.pricing-page-nav {
  background-color: #ffffff;
}

.profile-page-sidebar {
  background-color: #ffffff;
  margin-right: 0.1rem;
  border-bottom: none;
}

.pricing-page-nav.nav-tabs .nav-item,
.profile-page-sidebar.nav-tabs .nav-item {
  margin-bottom: 0 !important;
}

.pricing-page-nav.nav-tabs .nav-link {
  border-radius: 0;
  border-width: 0 1px 0 0;
  border-color: #e0e0e0;
  padding: 8px 16px 8px 16px;
  font-size: 17px;
  color: #404040;
}

.profile-page-sidebar.nav-tabs .nav-link {
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-color: #eff1f7;
  padding: 8px 16px 8px 16px;
  font-size: 16px;
  font-weight: 501;
  color: rgb(114, 118, 118);
}

.pricing-page-nav li a:hover,
.profile-page-sidebar li a:hover {
  cursor: pointer;
}



.card-brand-logo {
  width: 60px;
  margin-right: 10px;
}

.card-text {
  font-weight: 600;
  margin-right: 10px;
}

.expiry-text {
  font-size: 14px;
  color: rgb(141, 138, 138)
}

.cards-list li {
  font-size: 14px;
  padding: 5px;
}

.cards-list li:hover {
  background-color: #f1f1f1;
}

.cards-list .card-text {
  font-size: 15px;
}

/* buy credits modal css */
.buy-credits-modal.modal-dialog {
  max-width: 800px;
  /* max-height: 700px; */
}

.buy-credits-modal .modal-header {
  background-color: #f4f5f7;
  padding: 10px 15px;
}

.buy-credits-modal .modal-header .modal-title>small {
  font-size: 79%;
}

.buy-credits-modal .modal-header .close {
  padding: 12px 15px;
  margin-top: -9px;
  margin-right: -14px;
}

.buy-credits-modal .modal-footer {
  padding: 15px 15px 0 15px;
  flex-direction: column;
}

.buy-credits-modal .credit-modal-content-body>div:nth-child(1) {
  padding: 15px 0;
}

.credit-modal-content {
  padding: 10px;
  gap: 10px !important;
  display: flex;
  justify-content: space-between;

}

.credit-modal-content-body {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
}

.buy-credits-modal .modal-body {
  background-color: #f4f5f7;
  padding: 0px;
}

.buy-credits-modal .cards-list-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 0.25rem;
  margin-top: 2rem;
}

.card-list_header {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  background-color: #f4f5f7;
}

.buy-credits-modal .add-card {
  max-width: 700px;
  margin: 0 auto;
}

.buy-credits-modal .card-images>li {
  list-style: none;
  margin-top: 3px;
}

.buy-credits-modal .card-images {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1px;
  justify-content: center;
}

.buy-credits-modal .pricing-card-images {
  margin-top: 5px;
  padding: 10px;
}

.clearout-help-container {
  color: #999;
  font-size: 13px;
  line-height: 1.5;
  margin: 0 0 10px;
  line-height: normal;
}

.clearout-help-container>ul {
  margin: 0;
  padding-inline-start: 15px;
}

.overview-stats {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.performance-stats {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.performance-stats .stat-heading {
  display: block;
  text-align: left;
  font-size: 15px;
  color: #484848;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "open_sanssemibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  letter-spacing: 0.6px;
}

.performance-stats .stat-value {
  display: block;
  font-size: 40px;
  color: #4c4c4c;
  margin-bottom: 0;
}

.performance-stats .stats-bar {
  height: 3px;
  background: linear-gradient(to right, #00bcd4 0%, #6a9bcd 50%);
  width: 75%;
}

.performance-stats-heading {
  text-align: center;
  font-size: 30px !important;
  color: #334a4a !important;
  font-weight: 400;
  line-height: 1.2;
}

.versioncheck.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

.custom-timezone-picker>input {
  width: 100% !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
}

.custom-timezone-picker>ul.open li>button {
  padding: 8px;
  background-color: #fff;
}

.custom-timezone-picker>ul.open li>button:hover {
  background-color: whitesmoke;
}

.custom-timezone-picker>ul>li {
  background-color: #fff;
}

.min-credits-note {
  font-size: 15.5px;
  margin: 0 auto;
  margin-bottom: 0;
}

.min-credits-note>button:hover {
  color: rgba(var(--theme-color));
  font-weight: bold;
  text-decoration: underline !important;
}

.btn-helptext-link {
  background-color: transparent;
  border: none;
  text-decoration: none !important;
  color: #404040 !important;
  font-weight: normal;
}

.credits-charged-helptext {
  font-size: 15.5px;
  margin: 0 auto;
  margin-bottom: 0;
  margin-top: 15px;
}

.credits-charged-helptext>button:hover {
  text-decoration: underline !important;
}

.download-note {
  font-size: 14px;
  margin-bottom: 0;
  padding: 5px 15px;
}

.total-breakup {
  margin-right: 0 !important;
  justify-content: flex-end;
  width: 100%;
  display: block;
}

.total-breakup-table {
  display: flex;
  max-width: 290px;
  margin-left: auto;
  margin-bottom: 5px;
  text-align: end;
  background-color: inherit !important;
}

.total-breakup-table th,
.total-breakup-table td {
  padding: 0 0 5px 10px;
  font-size: 15.5px;
  color: #3d4042;
  text-align: right;
}

.total-breakup-table .total-value td,
.total-breakup-table .total-value th {
  font-size: 16px;
  color: #3d4042;
  font-weight: bold;
}

.stripe-logo {
  width: 120px;
  border-radius: 6px;
}

.support-text {
  font-size: 12px;
  margin: 0px;
  letter-spacing: 0.3px;
  line-height: 1.4;
  padding-top: 5px;
}

.custom-pricing-text {
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0;
}

.custom-pricing-text.public {
  text-align: start !important;
  padding: 0px !important;
}

.billable_help_text {
  word-break: break-word;
  text-align: left;
  width: 350px;
}

.api-page-title {
  display: flex;
  justify-content: center;
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: black;
  font-family: Inter, sans-serif;
}

.api-token-input {
  font-family: 'Lekton', sans-serif;
  font-size: 15px;
  color: #000000;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.api-token-input:focus {
  background-color: #fff !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-right: none;
}

.api-key-copied {
  color: #6fa51f;
  display: inline-block;
}

.api-key-copied svg {
  height: 20px;
  width: 20px;
  fill: currentColor;
  vertical-align: sub;
}

.alert-modal .modal-header {
  padding: 10px 15px;
}

.alert-modal .modal-body {
  width: 100%;
}

.error-svg {
  color: #ef594d;
  display: inline-block;
}

.error-svg svg {
  height: 50px;
  width: 50px;
  fill: currentColor;
  padding-right: 10px;
  vertical-align: sub;
}

.success-svg {
  color: #6fa51f;
  display: inline-block;
}

.success-svg svg {
  height: 50px;
  width: 50px;
  fill: currentColor;
  padding-right: 10px;
  vertical-align: sub;
}

.home-svg {
  color: #fff;
  display: inline-block;
}

.home-svg svg {
  height: 25px;
  width: 25px;
  fill: currentColor;
  vertical-align: sub;
}

.instant-search-recaptcha {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
}

.list-type-svg {
  color: #6fa51f;
  display: inline-block;
}

.list-type-svg svg {
  height: 28px;
  width: 28px;
  fill: currentColor;
  padding-right: 5px;
  vertical-align: sub;
}

.api-table-display p {
  font-size: 15px;
}

.addlist-upload-svg,
.addlist-apollo-svg,
.addlist-mailchimp-svg,
.addlist-acttive-campaign-svg,
.addlist-clevertap-svg,
.addlist-leads-svg,
.addlist-moosend-svg,
.addlist-hubspot-svg,
.addlist-mailerlite-svg,
.addlist-sendgrid-svg,
.addlist-automizy-svg {
  color: #797979;
  display: inline-block;
}

.lemlist-svg svg path {
  fill: #797979 !important;
}

.go-high-level-svg path {
  fill: #797979;
}

.active .go-high-level-svg path {
  fill: rgba(var(--theme-color));
}


.addlist-upload-svg svg,
.addlist-apollo-svg svg,
.addlist-mailchimp-svg svg,
.addlist-acttive-campaign-svg svg,
.addlist-clevertap-svg svg,
.addlist-moosend-svg svg,
.addlist-hubspot-svg svg,
.addlist-leads-svg svg,
.addlist-mailerlite-svg svg,
.addlist-sendgrid-svg svg,
.addlist-automizy-svg svg {
  height: 30px;
  width: 34px;
  fill: currentColor;
  padding-right: 5px;
  vertical-align: sub;
}

.add-ev-list-sidebar-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.add-ev-list-sidebar .nav-link.active {
  color: #404040 !important;
}

.add-ev-list-sidebar .nav-item {
  height: 50px;
}

.add-ev-list-sidebar .nav-link {
  height: 50px;
  display: flex;
  align-items: center;
  padding-bottom: 7px !important;
}

.active-campaign-colour-icon {
  width: 16px;
  margin-right: 0.5rem;
}

.verify-icon {
  height: 20px;
  padding-right: 3px;
}

.addlist-automizy-svg .automizy-add-list {
  fill: #797979;
}

.add-list-modal.modal-dialog,
.quick-validation-modal.modal-dialog {
  max-width: 1125px;
}

.add-list-modal.email-finder {
  max-width: 860px;
}

.quick-validation-modal .modal-content,
.add-list-modal .modal-content {
  height: 90vh;
}

.quick-validation-modal .modal-body {
  overflow-y: hidden;
}

.limits-table td {
  padding-top: 0;
  padding-left: 0;
  font-size: 15px;
  word-break: break-all;
}

.limits-table td:first-child {
  width: 150px;
}

.download-checkmark svg {
  height: 50px;
  width: 50px;
  fill: currentColor;
  padding-right: 5px;
  vertical-align: sub;
  margin-bottom: 10px;
}

.download-err-checkmark {
  color: #c54a41;
  display: inline-block;
}

.download-err-checkmark svg {
  height: 50px;
  width: 50px;
  fill: currentColor;
  padding-right: 5px;
  vertical-align: sub;
}

.add-list-page-content {
  display: flex;
}

.custom-input-outline:focus {
  box-shadow: none;
  border: 2px solid rgba(var(--theme-color));
}


@media(max-width: 768px) {
  .versioncheck.modal-dialog {
    min-height: calc(100vh - 20px);
  }

  .instant-search-recaptcha {
    justify-content: center !important;
  }

  .page-sub-header-nav {
    margin-top: 10px;
  }

  .add-list-page-content .nav-tabs {
    border-bottom: none;
  }

  .btn-toolbar .account-select-options {
    width: 136px !important;
  }
}

@media (max-width: 992px) {

  .quick-validation-modal.modal-dialog,
  .add-list-modal.modal-dialog {
    max-width: 720px;
  }

  .add-list-modal .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 950px) {
  .profile-page-sidebar {
    flex-direction: row !important;
    justify-content: space-evenly;
    margin-right: 0;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 768px) {

  .quick-validation-modal.modal-dialog,
  .add-list-modal.modal-dialog {
    max-width: 540px;
  }

  .limits-table td:first-child {
    width: auto;
  }

  .performance-stats-heading {
    font-size: 20px !important;
  }

  .overview-stats .col-sm-4 {
    width: 33.33%;
  }

  .performance-stats .stat-heading {
    font-size: 12px;
    white-space: normal;
    text-align: center;
    display: flex;
    height: 45px;
    align-items: center;
  }

  .performance-stats .stat-value {
    font-size: 25px;
  }

  .performance-stats .stats-bar {
    width: 120% !important;
  }

  .page-panel-heading>h3 {
    font-size: 16px;
  }
}

.account-select-options {
  width: 200px !important;

}

.status-data-quick-validation {
  text-align: center;
  font-size: 16px !important;
  letter-spacing: 1px;
  word-break: break-word;
  line-height: normal;
  font-weight: 600;
}

.show-json-container {
  font-size: 12px;
  text-align: left;
  margin: auto;
  width: 60%;
}

.download-tooltip-info-changes {
  background-color: whitesmoke;
  border-left: 1px solid #e8e8e8;
}

.apply-coupon-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 290px;
  margin-left: auto;
  float: right;
}

.apply-coupon-div>button:hover {
  font-weight: bold;
  text-decoration: underline !important;
}

.cancel-coupon {
  border: 1px solid #928c8c !important;
  border-radius: 40px;
  padding: 0 4px !important;
  background-color: #ddd;
  cursor: pointer !important;
  font-size: 19px;
}

.apply-coupon {
  border: 1px solid #58c058;
  border-radius: 40px;
  padding: 0px 4px;
  background-color: #58c058;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  margin-top: 5px;
}

.apply-coupon:focus {
  outline: none;
}

.coupon-code {
  border: 1px dashed #9d9fa0;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 0;
  font-size: 14px;
  color: #828282;
  font-weight: bold;
}

.coupon-discount-input:focus {
  box-shadow: none;
}

.hubspot-note {
  font-size: 13px;
  padding-left: 18px;
}

.delete-modal.modal-dialog {
  max-width: 450px;
}

.delete-modal .modal-title {
  font-size: 18px;
}

.delete-modal .modal-header,
.delete-modal .modal-body {
  padding: 10px 10px 10px 15px;
}

.delete-info {
  text-align: center;
  background-color: #4092c3;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-size: 14px;
  line-height: 17px;
  display: inline-block;
  margin-right: 5px;
}

.delete-info:hover {
  cursor: pointer;
}

.delete-info:before {
  content: '!';
  font-weight: bold;
  color: #fff;
  line-height: normal;
  vertical-align: top;
}

.feature-checkmark svg {
  height: 20px;
  width: 20px;
  fill: currentColor;
  padding-right: 5px;
  vertical-align: sub;
}

.feature-checkmark {
  color: #6fa51f;
  display: inline-block;
}

.cancel-subscription .source-select>div:first-child {
  padding-left: 5px !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.upgrade_to_team svg {
  /* height: 20px; */
  width: 20px;
  fill: #fff;
}

.react-switch-selector-option-label {
  margin: .3em !important;
}

.react-switch-selector-profile {
  width: 290px !important;
}

.react-switch-selector-option>label>input:checked+span {
  color: rgba(var(--theme-color));
  font-weight: 501;
}

.react-switch-selector-option>label>input+span {
  font-weight: 501;
}

.email-verification-settings-tooltip {
  z-index: 10121012;
  width: 250px;
  text-align: left;
  opacity: 0.8 !important;
  padding: 0.15rem 0.25rem !important;
}

@media(max-width: 768px) {
  .react-switch-selector-profile {
    width: 100% !important;
    margin: 0.8rem 0 0.8rem 0;
  }

  .react-switch-selector-option>label>span {
    font-size: 14px;
  }

  .cards-list .card-text {
    font-size: 14px;
  }

  .min-credits-note {
    font-size: 13.5px;
    text-align: center;
  }

  .prospect-sub-header {
    flex-direction: column;
  }
}

.overview-lists-dropdown {
  position: relative;
}

.overview-lists-dropdown__content {
  display: none;
  right: 0;
  padding: 10px 10px 10px 0px;
  position: absolute;
  top: 100%;
  background-color: #FFF;
  z-index: 9999;
  width: max-content;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  margin-top: 2px;
}

.overview-lists-dropdown__content a {
  color: #222;
}

.overview-lists-dropdown:hover .overview-lists-dropdown__content {
  display: flex;
  flex-direction: column;
}

.integration-account-select__control {
  height: 40px;
}

.bulk-upload-duplicate-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 5px;
  align-items: flex-end;
}

.bulk-upload-duplicate-container>span {
  text-align: end;
  word-break: break-all;
}

.duplicate-file-name-hyperlink {
  color: #007bff;
  cursor: pointer;
}

.duplicate-file-name-hyperlink:hover {
  text-decoration: underline;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 100% !important;
}

.page-container {
  margin-top: 25px;
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 10px;
}

.page-container .filters-div {
  display: flex;
  gap: 0.4rem;
  padding: 5px 0px 4px 0px;
  border-radius: 5px;
}

/* .page-container .filters-div .btn,
.page-container .filters-div input {
  height: 37px;
} */

.history-table-content {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 6px;
}

@media (min-width: 1501px) {

  .page-container,
  .page-header {
    max-width: 1500px !important;
  }

}

.history-breadcrumb {
  display: flex;
}

.history-breadcrumb ol {
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0;
  background-color: unset !important;
  align-items: center;
}

.history-breadcrumb .back-btn {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #03a9f4;
  cursor: pointer;
}

.history-breadcrumb-heading-container {
  width: 90%;
}

.history-breadcrumb-heading-container h3 {
  font-size: 20px;
}

/* chip css - starts */

.chip__container {
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  align-items: baseline;
}

.chip__item {
  background-color: #f0f0f2;
  border-radius: 0.1rem;
  line-height: 1;
  display: flex;
  color: black;
}

.chip__item .chip__val {
  padding: 0.25rem;
  font-size: 14px;
}

.chip__item .chip__val.clickable {
  cursor: pointer;
}

.chip__item.red {
  background-color: rgba(255, 0, 0, 0.2)
}

.chip__label {
  padding: 0.25rem;
  padding-left: 0.5rem;
  font-weight: 501;
}

.chip-close__icon {
  padding: 0.25rem;
  border-radius: 0.1rem;
  display: flex;
  align-items: center;
}

.chip-close__icon:hover {
  background-color: #FFBDAD;
  color: #DE350B;
}

/* chip css - ends */

.tooltip-clearout {
  opacity: 0.8 !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 0.85rem !important;
  z-index: 10121012;
  max-width: 340px;
  word-break: break-word;
  white-space: pre-wrap;
  width: auto;
}

.CO-read-more-text .read-or-hide {
  cursor: pointer;
  color: #007bff;
  margin-left: 3px;
}

.read-more-create-app {
  color: rgba(var(--theme-color));
  font-size: 15px;
}

/* Override Freshchat default right position spacing */
#fc_frame {
  bottom: 10px !important;
  right: 0px !important;
}

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.text-clearout-orange {
  color: rgba(var(--theme-color));
}

/* g2 review css */
.iframe-container .modal-content {
  height: 90vh;
}

.iframe-container iframe {
  border: unset !important;
}

.iframe-container .modal-body {
  padding: 0;
}

.g2-header-spinner-container {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 100%;
  width: 100%;
  background: #fff;
  justify-content: center;
}

.g2-header-spinner-container>div {
  color: rgba(var(--theme-color));
  width: 3rem;
  height: 3rem;
}

.word-break-all .delete-your-account {
  font-weight: 600;
}

/* nish's first css starts here  */
.list-new-badge {
  position: relative;
  font-weight: 501 !important;
  font-size: 12px !important;
  background-color: rgba(var(--theme-color));
  translate: 1px -13px;
  font-weight: 700;
  font-size: 12px;
  color: white !important;
  line-height: 0.8;
  padding: 0.25em 0.4em;
}

.no-activities-icon {
  opacity: 0.52;
}

@media (max-width: 1280px) {
  #main-container {
    padding: 0 0.5rem;
  }
}

.subheader-hint-tooltip {
  opacity: 0.7 !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 0.85rem !important;
  z-index: 10121012;
  max-width: 350px;
  word-wrap: break-word;
  white-space: pre-wrap;
  width: auto;
  text-align: left;
}

.account-delete-btn {
  width: 180px;
  padding: 7px;
  border-radius: 5PX;
  border: solid 1px red;
  color: red;
  background: white;
}


.sso-activate-btn {
  border: 1px solid rgba(var(--theme-button-color));
  color: rgba(var(--theme-button-color)) !important;
  background: #ffffff;
  font-weight: 600;
}

.sso-activate-btn:hover,
.sso-activate-btn:active {
  background-color: rgba(var(--theme-button-color), 0.1) !important;
  border-color: rgba(var(--theme-button-color), 0.7) !important;
  color: rgba(var(--theme-button-color)) !important;
  box-shadow: none !important;
}

.account-talk-to-support-btn {
  width: 180px;
  padding: 7px;
  border-radius: 5PX;
  border: solid 1px green;
  color: green;
  background: white;
}

.select-error {
  border: 1px solid red;
}

.select-container {
  font-size: '15px';
  width: '150px'
}

.account-delete-btn:disabled {
  width: 180px;
  padding: 7px;
  border-radius: 5PX;
  border: solid 1px rgb(255, 123, 123);
  color: rgb(255, 123, 123);
  background: white;
}

.delete-account-heading {
  font-weight: 600;
  font-size: 16px;
}

.account-delete-dropdown-btn {
  background: white;
  border: 1px solid #d9dcd9;
  color: black;
}

.account-deletion-warnings {
  margin-left: 16px;
  padding: 0px;
}

.delete-account-dropdown {
  width: 180px;
}

@media screen and (max-width: 768px) {
  .delete-account-dropdown {
    width: 100%;
  }

  .account-delete-btn,
  .account-delete-btn:disabled,
  .account-talk-to-support-btn {
    width: 150px;
    margin: 5px;
  }

}

.delete-account-dropdown .css-1pahdxg-control,
.delete-account-dropdown .css-1pahdxg-control:hover,
.delete-account-dropdown .css-1s2u09g-control {
  height: 30px !important;
  min-height: unset !important;
  border-color: #cccccc;
  align-content: center !important;
  box-shadow: none;
}

.delete-account-dropdown .css-319lph-ValueContainer {
  height: inherit !important;
  align-content: center !important;
}

.delete-account-dropdown .css-1hb7zxy-IndicatorsContainer {
  height: inherit !important;
  align-content: center !important;
}

.delete-account-dropdown .css-1gtu0rj-indicatorContainer,
.delete-account-dropdown .css-1gtu0rj-indicatorContainer:hover,
.delete-account-dropdown .css-tlfecz-indicatorContainer {
  padding: 5px !important;
}

.mail-us-link a {
  color: rgba(var(--theme-color));
}

.delete-account-other-reasons {
  height: 30px;
  border: 1px solid #ced4da;
}

.delete-account-other-reasons:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.request-seat-modal-body .css-188vgcs-menu {
  text-align: left;
}

.custom-pulse-loader-color>.css-1xdhyk6 span {
  background-color: rgba(var(--theme-button-color));
}

.cancel-modal.modal-dialog {
  min-width: 600px !important;
}

.cancel-modal.modal-body.sub-title {
  font-size: 18px;
}

.cancel-description {
  margin-top: 20px;
}

.cancel-list {
  margin-top: 5px;
  margin-bottom: 10px;
}

.cancel-reason-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 76px;
  max-width: 400px;
  gap: 8px;
}

.cancel-other-reason {
  width: 400px;
  margin-left: 25px;
}

.custom-clearout-checkbox {
  font-size: 14px;
  padding: 5px 5px 5px 25px;
}

.custom-clearout-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: none !important;
  /* Remove the background image */
  content: '\f00c';
  font-family: 'FontAwesome';
  color: rgba(var(--theme-color));
  font-size: 10px;
  margin-left: 0.19rem;
  margin-top: 0.1rem;
  background-color: none !important;
}

.custom-clearout-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: transparent !important;
}

.custom-clearout-checkbox .custom-control-label::before {
  border: 1px solid rgba(var(--theme-color)) !important;
}

.custom-clearout-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgb(247 178 103 / 90%);
  border: none !important;
}

.custom-clearout-checkbox .custom-control-input:focus {
  outline: none !important;
  box-shadow: none !important;
  /* Some browsers also add a box-shadow for focus */
}

.clearout-theme-link {
  color: rgba(var(--theme-color));
  margin: 0 3px;
}

.clearout-theme-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .cancel-modal.modal-dialog {
    min-width: 100% !important;
  }

  .cancel-reason-container {
    margin: 0px 10px;
  }

  .cancel-modal.modal-dialog {
    min-width: 100% !important;
  }

  .cancel-subscription .icon {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .cancel-description ul {
    padding-left: 20px;
  }

  .cancel-btn {
    width: 100%;
  }

  .source-select,
  textarea {
    width: 100%;
  }
}

.back-btn_modal {
  font-size: 15px;
  color: #03a9f4;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px 6px;
  border: 1px solid #03a9f4;
  margin: 10px;
}

@media screen and (max-width: 768px) {

  .credit-modal-content {
    flex-direction: column;
  }

}

.calendly-modal {
  max-width: 800px;
  height: 90vh;
}

.custom-header-tooltip {
  opacity: 0.8 !important;
  padding: 0.15rem 0.25rem !important;
  font-size: 0.85rem !important;
  z-index: 10121012;
  max-width: 220px;
  word-wrap: break-word;
  white-space: normal;
  width: auto;
  line-height: 1.2rem;
  text-align: left;
}

.custom-header-tooltip .tooltip-list {
  padding: 5px;
  padding-left: 20px;
}

.cleaorut-modal-terms {
  background-color: #f5f5f5;
  padding: 4px;
  align-items: flex-start;
  border-radius: 8px;
  font-size: 14px;
}

.clearout-ul-list>li::marker {
  color: rgba(var(--theme-color));
}

.delete-account-btn_container {
  display: flex;
  justify-content: space-between;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: rgba(var(--theme-color));
  background-color: rgba(var(--theme-color));
}

.clearout-show-more-toggle-btn {
  background-color: transparent !important;
  color: rgba(var(--theme-color)) !important;
  outline: none !important;
  box-shadow: none !important;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.strict-domain-check-warning {
  color: #495057;
}