.app-container {
  background-color: #f6f6f6;
  float: left;
  width: 100%;
}

.btn-clearout:focus {
  outline: none;
  outline-color: transparent;
  box-shadow: none;
}

.btn-clearout.disabled,
.btn-clearout:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.btn-clearout-link {
  background-color: transparent;
  border: none;
  text-decoration: none !important;
  color: #03a9f4 !important;
  font-weight: normal;
}

.btn-clearout-link:hover {
  cursor: pointer;
}

.btn-clearout-link:focus {
  color: #0b8df8;
  outline: none;
  box-shadow: none;
}

.btn-clearout-link:disabled{
  cursor: not-allowed;
}

.error-message {
  color: #ff0000 !important;
  font-weight: 500 !important;
  margin-bottom: 0;
  font-size: 14px;
  margin-left: 3px;
}

.paste-email-error {
  color: #0059ff;
  margin-bottom: 0;
  font-size: 14px;
  margin-left: 3px;
}

.success-message {
  color: green;
  font-size: 15px;
  margin-bottom: 0;
}

.unknown-error-message {
  color: #808080;
  font-size: 14px;
  margin-bottom: 0;
}

.no-padding {
  padding: 0;
}

.btn-clearout-export,
.btn-clearout-download {
  align-self: stretch;
}

.btn-input-file-download {
  color: #00bcd4;
  padding: 0;
  margin-left: 5px;
  padding: 2px 3px 2px 5px;
}

.btn-input-file-download:hover {
  border-color: #00bcd4;
  color: #00bcd4;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: none;
}

.btn-clearout-refresh {
  box-shadow: none;
  color: #717171 !important;
  border-color: #c3c3c3 !important;
}

.btn-outline-secondary {
  color: #717171 !important;
  border-color: #c3c3c3 !important;
}

.btn-outline-secondary:hover {
  color: #fff !important;
}

.btn-no-data {
  padding: 0px;
}

/* to remove auto complete input filed background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

button:disabled {
  cursor: not-allowed;
}

.modal-footer {
  border-top: none;
  background-color: whitesmoke;
  padding: 8px 5px;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.list-info-clearout-breadcrumb {
  display: flex;
  justify-content: space-between;
}

.clearout-breadcrumb .breadcrumb,
.list-info-clearout-breadcrumb .breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
}

.list-info-clearout-breadcrumb .breadcrumb .breadcrumb-item,
.clearout-breadcrumb .breadcrumb .breadcrumb-item {
  font-size: 17px;
  line-height: normal;
}

.list-info-breadcrumb>.breadcrumb {
  padding: 10px;
}

.readMoreText {
  display: inline-block;
  padding: 0 .5em;
  font-size: 15px;
  line-height: 1;
  text-decoration: underline;
  font-weight: 400 !important;
}

.clearout-danger-btn {
  color: #fff !important;
  background-color: #c54a41 !important;
  border-color: #c54a41 !important;
  box-shadow: none;
  font-weight: 700;
}

.co-btn-toolbar {
  justify-content: flex-end;
}

.react-bootstrap-table-pagination {
  margin-top: 10px;
}

.custom-toggle-notification.react-toggle--checked .react-toggle-track {
  background-color: rgba(var(--theme-button-color));
}

.custom-toggle-notification .react-toggle-track {
  background-color: #b3b3b3;
  height: 18px;
  width: 37px;
}

.custom-toggle-notification .react-toggle-thumb {
  border: transparent;
}

.custom-toggle-notification .react-toggle-thumb {
  width: 15px;
  height: 15px;
}

.custom-toggle-notification.react-toggle--checked .react-toggle-thumb {
  left: 20px;
}

.custom-toggle-notification.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(var(--theme-button-color));
}

.custom-toggle-notification .react-toggle-track-check {
  width: 14px;
  height: 0px;
  left: 5px;
  color: #fff;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px rgba(var(--theme-button-color)) !important;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px rgba(var(--theme-button-color)) !important;
}

.notification-text {
  font-size: 14px;
  margin: 0px;
  line-height: 1.4;
}

.remove-input-file {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #a74a2d !important;
  padding: 0;
  align-self: center;
  font-size: 15px;
}

.show-password-button,
.hide-password-button {
  top: 1em;
  position: absolute;
  right: .5em;
  background: 0 0;
  border: none;
  font-size: .835rem;
  color: #9faebb;
  padding-left: .25em;
  -webkit-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out;
  cursor: pointer;
}

.show-password-button::before {
  content: "\F06E";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 1.5em;
  position: absolute;
  top: 0px;
  left: -1em;
}

.hide-password-button::before {
  content: "\F070";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 1.5em;
  position: absolute;
  top: 0px;
  left: -1em;
}

.show-password-button.left-zero::before {
  left: -2em; 
  top: -3px;
}

.hide-password-button.left-zero::before {
  left: -2em; 
  top: -3px;
}

.show-password-button:focus,
.hide-password-button:focus {
  outline: none;
}

.buy-credits-modal #paypal-button-container {
  width: 80%;
  float: right;
  max-width: 500px;
}

.login-form-input.modal-current-password,
.login-form-input.modal-new-password {
  outline: none;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  box-shadow: none;
  border-radius: 0;
}

.edit-pwd-modal .modal-body {
  padding-top: 0px !important;
}

.lh-1 {
  line-height: 1;
}

@media(max-width: 768px) {
  .list-info-clearout-breadcrumb {
    flex-direction: column;
  }

  .co-btn-toolbar {
    margin-top: 10px;
  }

  .co-btn-toolbar>button {
    flex-basis: 0;
    flex-grow: 1;
  }

  .btn-clearout-export,
  .btn-clearout-download {
    width: 100%;
    max-width: 100%;
  }

  .buy-credits-modal #paypal-button-container {
    width: auto;
  }
}

.download-try-link {
  color: #007bff;
  margin-bottom: 0;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.download-try-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.btn-clearout-outline {
  color: #404040 !important;
  background-color: rgb(246, 246, 246) !important;
  height: 39px;
  border: 1px solid rgb(236, 236, 236) !important;
  align-items: center;
  transition: color 50ms ease-in-out 0s,
    background-color 50ms ease-in-out 0s,
    border 50ms ease-in-out 0s;
  border-radius: 0.25rem;
  display: flex;
  outline: 0;
  font-weight: 600;
  cursor: pointer;
  box-shadow: none !important;
  justify-content: center;
}

.btn-clearout-outline-secondary {
  color: #404040 !important;
  background-color: #ffffff !important;
  border: 1px solid rgb(236, 236, 236) !important;
  height: 39px;
  align-items: center;
  transition: color 50ms ease-in-out 0s,
    background-color 50ms ease-in-out 0s,
    border 50ms ease-in-out 0s;
  border-radius: 0.25rem;
  display: flex;
  outline: 0;
  font-weight: 700;
  cursor: pointer;
  box-shadow: none !important;
  justify-content: center;
}

.btn-clearout-outline:not([disabled]):hover,
.btn-clearout-outline:not([disabled]):active,
.btn-clearout-outline-secondary:not([disabled]):hover,
.btn-clearout-outline-secondary:not([disabled]):active {
  background-color: rgba(var(--theme-button-color), 0.1) !important;
  border-color: rgba(var(--theme-button-color), 0.7) !important;
  color: rgba(var(--theme-button-color)) !important;
}

.btn-clearout-outline-primary {
  border: 1px solid rgba(var(--theme-button-color));
  color: rgba(var(--theme-button-color)) !important;
  background: #ffffff;
  font-weight: 600;
}

.btn-clearout-outline-primary:hover,
.btn-clearout-outline-primary:active {
  background-color: rgba(var(--theme-button-color), 0.1) !important;
  border-color: rgba(var(--theme-button-color), 0.7) !important;
  color: rgba(var(--theme-button-color)) !important;
  box-shadow: none !important;
}

.btn-clearout-outline-primary:focus {
  border-color: rgba(var(--theme-button-color), 0.7) !important;
  color: rgba(var(--theme-button-color)) !important;
  box-shadow: none !important;
}